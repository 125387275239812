import React from 'react'
import Online from '../online/Online'
import './rightbar.css'

import {Users} from '../../dummyData';



export default function Rightbar({profile}) {


const HomeRightbar=()=>{

  return <>
   <div className="birthdayContainer">
            <img className='birthdayImg' src="/assets/gift.png" alt="" />
            <span className="birthdayText">
              <b>Pola foster</b> and other <b>33 Other</b> friends birthday today</span>
        </div>
        <img src="/assets/ad.png" alt="" className="rightbarAd" />
        <h4 className='rightbarTitle'>Online Friends</h4>
        <ul className="rightbarFriendList">
        {Users.map(u=>(
          <Online key={u.id} user={u}/>
        ))}
          
        </ul>
  </>
}

const ProfileRightBar=()=>{
  return <>
    <h4 className='rightbarTitle'>User Information</h4>
    <div className="rightbarInfo">
      <div className="rightbarInfoItem">
        <span className="rightbarInfoKey">City:</span>
        <span className="rightbarInfoKeyValue"> New York</span>
      </div>
      <div className="rightbarInfoItem">
        <span className="rightbarInfoKey">From:</span>
        <span className="rightbarInfoKeyValue"> Gaibandha</span>
      </div>
      <div className="rightbarInfoItem">
        <span className="rightbarInfoKey">Relationship:</span>
        <span className="rightbarInfoKeyValue"> Married</span>
      </div>
    </div>

    <h4 className='rightbarTitle'>User Friends</h4>
    <div className="rightbarFollowings">
    <div className="rightbarFollowing">
      <img src="/assets/post/1.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">Sazzad hossain</span>
    </div>
    <div className="rightbarFollowing">
      <img src="/assets/post/2.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">Sazzad hossain</span>
    </div>
    <div className="rightbarFollowing">
      <img src="/assets/post/3.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">faizan Sazzad hossain</span>
    </div>
    <div className="rightbarFollowing">
      <img src="/assets/post/4.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">Sazzad hossain</span>
    </div>
    <div className="rightbarFollowing">
      <img src="/assets/post/5.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">Sazzad hossain</span>
    </div>
    <div className="rightbarFollowing">
      <img src="/assets/post/6.jpeg" alt="" className="rightbarFollowingImg" />
      <span className="rightbarFollowingName">Sazzad Faizan hossain</span>
    </div>
    </div>
  </>
}

  return (
    <div className='rightbar'>
      <div className="rightbarWrapper">
        {profile ? <ProfileRightBar/> : <HomeRightbar/>}
       
      </div>
    </div>
  )
}
