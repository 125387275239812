import React from 'react'
import './share.css'

import {EmojiEmotions, Label, PermMedia, Room} from '@mui/icons-material';




export default function Share() {
  return (
    <div className='share'>
        <div className="shareWrapper">
            
            <div className="shareTop">

                    <img className='shareTopProfileImg' src='/assets/person/1.jpeg' alt=''/>
                    <input placeholder='whats in your mind' type="text" className="shareInput" />

            </div>
            <hr className='shareHr'/>
            <div className="shareBottom">
                <div className="shareOptions">
                    <div className="shareOption">
                        <PermMedia htmlColor='tomato' className='shareIcon'/>
                        <span className='shareOptionText'>photo or videos</span>
                    </div>
                    <div className="shareOption">
                        <Label htmlColor='blue'  className='shareIcon'/>
                        <span className='shareOptionText'>Tag</span>
                    </div>
                    <div className="shareOption">
                        <Room htmlColor='green' className='shareIcon'/>
                        <span className='shareOptionText'>Locations</span>
                    </div>
                    <div className="shareOption">
                        <EmojiEmotions htmlColor='goldenrod' className='shareIcon'/>
                        <span className='shareOptionText'>Feelings</span>
                    </div>
                    
                </div>
                <button className="shareButton">Share</button>
            </div>
            
        </div>

    </div>
  )
}
